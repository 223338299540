$(document).ready(function() {

    /*******************************************/
    //Form Validation
    $('.validate-form').each(function() {
        $(this).validate({
            submitHandler: function(form) {
                form.submit();
            },
            rules: {
                required: true
            },
            errorPlacement: function(error, element) {
                error.appendTo(element.parents('.form-field'));
            },
            highlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).parents('.form-field').addClass(errorClass).removeClass(validClass);
                }
            },
            unhighlight: function(element, errorClass, validClass) {
                if (element.type === 'radio') {
                    this.findByName(element.name).parents('.form-field').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).parents('.form-field').removeClass(errorClass).addClass(validClass);
                }
            }
        });
    });


    /*******************************************/
    //Custom Select Dropdown 
    $(function() {
        $('.custom-select-dropdown').selectric();
    });

    /*******************************************/
    //Lightbox Initiations
    $('.open-popup-link').magnificPopup({
        type:'inline',
        showCloseBtn: true,
        closeOnBgClick: true,
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        midClick: true
    });

    $('.lightbox-manual-close-trigger').click(function(event) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    /*******************************************/
    // Tabs
    $('.js-tab-triggers a').on('click', function(e) {
        e.preventDefault();
        $('.js-tab-triggers a').not(this).removeClass('active');
        $(this).addClass('active');
        var getTarget = $(this).attr('href');
        $('.tab-content').not(getTarget).hide();
        $(getTarget).show();
    });

    /*******************************************/

});


/*******************************************/
$.fn.toggleHTML = function(t1, t2) {
    if (this.html() == t1) this.html(t2);
    else this.html(t1);
    return this;
};


/*******************************************/
//Main Menu
function slideMenu(action){ 
    var $menu = $('#mobile-menu') 
    if (!action) { toggle(); return }
    if (action == "open") { open(); return }
    if (action == "close") { close(); return }

    function open(){
        $("body").addClass('open-menu');
        $menu.attr('status', 'open');
    }
    function close(){
        $("body").removeClass('open-menu');
        $menu.attr('status', 'closed');
    }
    function toggle(){
        var status =  $menu.attr('status');
        if (status == "open"){ close(); return }
        if (status == "closed"){ open(); return }
    }
}

$('#mobile-menu li').each(function(){
        $(this).has("ul").addClass('mobile-menu-parent');
});

$( ".mobile-menu-parent .icon" ).on("click", function(){
    $(this).siblings('ul').slideToggle(150);
});

//prevent slide up
$(".mobile-menu-parent").on("click", "ul", function(event){
    event.stopPropagation()
})

$(document).ready(function() {
    var popup = $('#signin');
    $('.js-signin-trigger').on('click', function(){
        popup.removeClass('hide').addClass('show');
    });
    $(popup).on('click', '.popup-bg, .popup-close', function(e){
        e.stopPropagation();
        popup.removeClass('show').addClass('hide');
    });
});

/*******************************************/
// Header Highlighter
$(document).ready(function() {
    var headerHref = "#site-header";
    var mobileHref = "#mobile-menu";
    var myURL = "/" + location.pathname.split('/')[1];

    $(headerHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })

    $(mobileHref + ' li').each(function(){
        if ($(this).find('a').attr('href') == myURL) {
            $(this).find('a').addClass("selected")
            return
        }
    })
});

/*******************************************/
//Filtering articles

$(document).ready(function() {
    var container = $('.js-filter-block'),
    filterContainer = container.find('.js-filters'),
    itemsContainer = container.find('.js-filter-items'),
    items = itemsContainer.find('.js-item'),
    filters = container.find('.js-filter');

    function removeFilterLimiter() {
        if(filterContainer.hasClass('max-filters')) {
            filterContainer.removeClass('max-filters');
        }
    }

    function addFilterItems(filterValue) {
        $(items).each(function (index, value) {
            var valueOne = $(this).data('filter-one'),
            valueTwo = $(this).data('filter-two');
            if(valueOne == filterValue) {
                $(this).addClass('js-order-one');
            } else if(valueTwo == filterValue) {
                $(this).addClass('js-order-two');
            }
        });
    }

    function removeFilterItems(filterValue) {
        $(items).each(function (index, value) {
            var valueOne = $(this).data('filter-one'),
            valueTwo = $(this).data('filter-two');
            if(valueOne == filterValue) {
                $(this).removeClass('js-order-one');
            } else if(valueTwo == filterValue) {
                $(this).removeClass('js-order-two');
            }
        });
    }

    $(filters).on('click', function() {
        var activeFilters = container.find('.js-filter.active').length,
        filterValue = $(this).data('filter');

        if($(this).data('filter') == 'all') {
            removeFilterLimiter();
            items.removeClass('js-order-one js-order-two');
            filters.removeClass('active');
        } else if(activeFilters == 2 && !$(this).hasClass('active')) {
            filterContainer.addClass('max-filters');
        } else if($(this).hasClass('active')) {
            removeFilterLimiter();
            removeFilterItems(filterValue);

            $(this).removeClass('active');
        } else if(!$(this).hasClass('active')) {
            removeFilterLimiter();
            addFilterItems(filterValue);

            $(this).addClass('active');
        } else {
            console.log('error as click filtered through all of "if" conditions');
        }
    });

});

